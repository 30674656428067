module Utilities {

    export function serialize(form: any) {
        if (!form || form.nodeName !== "FORM") {
            return;
        }
        var i, j, q = {};
        for (i = form.elements.length - 1; i >= 0; i = i - 1) {
            if (form.elements[i].name === "" || form.elements[i].classList.contains("non-serialized")) {
                continue;
            }
            switch (form.elements[i].nodeName) {
                case 'INPUT':
                    switch (form.elements[i].type) {
                        case 'text':
                        case 'hidden':
                        case 'password':
                        case 'button':
                        case 'reset':
                        case 'submit':
                            q[form.elements[i].name] = form.elements[i].value;
                            break;
                        case 'checkbox':
                        case 'radio':
                            if (form.elements[i].checked) {
                                if (!q[form.elements[i].name])
                                    q[form.elements[i].name] = form.elements[i].value;
                                else
                                    q[form.elements[i].name] = String.format("{0},{1}", q[form.elements[i].name], form.elements[i].value);
                            }
                            break;
                        case 'file':
                            break;
                    }
                    break;
                case 'TEXTAREA':
                    q[form.elements[i].name] = form.elements[i].value;
                    break;
                case 'SELECT':
                    switch (form.elements[i].type) {
                        case 'select-one':
                            q[form.elements[i].name] = form.elements[i].value;
                            break;
                        case 'select-multiple':
                            for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                                if (form.elements[i].options[j].selected) {
                                    if (!q[form.elements[i].name])
                                        q[form.elements[i].name] = [form.elements[i].options[j].value];
                                    else
                                        q[form.elements[i].name].push(form.elements[i].options[j].value);
                                }
                            }
                            break;
                    }
                    break;
                case 'BUTTON':
                    switch (form.elements[i].type) {
                        case 'reset':
                        case 'submit':
                        case 'button':
                            q[form.elements[i].name] = form.elements[i].value;
                            break;
                    }
                    break;
            }
        }
        return q;
    }

    export function deserialize(form: any, data: any) {

        if (!form || form.nodeName !== "FORM")
            return;

        var i, j, q = [];
        for (i = form.elements.length - 1; i >= 0; i = i - 1) {


            if (form.elements[i].name === "" || form.elements[i].classList.contains("non-serialized"))
                continue;


            switch (form.elements[i].nodeName) {
                case 'INPUT':
                    switch (form.elements[i].type) {
                        case 'text':
                        case 'hidden':
                        case 'password':
                        case 'button':
                        case 'reset':
                        case 'submit':
                            form.elements[i].value = data[form.elements[i].name];
                            break;
                        case 'checkbox':
                        case 'radio':
                            form.elements[i].checked = (data[form.elements[i].name] + ",").indexOf((form.elements[i].value + ",")) !== -1;
                            break;
                        case 'file':
                            break;
                    }
                    break;
                case 'TEXTAREA':
                    form.elements[i].value = data[form.elements[i].name];
                    break;
                case 'SELECT':
                    switch (form.elements[i].type) {
                        case 'select-one':
                            form.elements[i].value = data[form.elements[i].name];
                            break;
                        case 'select-multiple':
                            for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                                if (form.elements[i].options[j].selected) {

                                    // TODO q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].options[j].value));
                                }
                            }
                            break;
                    }
                    break;
                case 'BUTTON':
                    switch (form.elements[i].type) {
                        case 'reset':
                        case 'submit':
                        case 'button':
                            form.elements[i].value = data[form.elements[i].name];
                            break;
                    }
                    break;
            }
        }
    }


    export function findAncestor(element: HTMLElement, cls: string) {
        while ((element = element.parentElement) && !element.classList.contains(cls));
        return element;
    }

    export function findDescendant(element: HTMLElement, cls: string) {
        return element.querySelector("." + cls);
    }


    export enum accents {
        azura,
        hazmat,
        gray,
        amber,
        crimson,
        mauve,
        olive,
        steel,
        steelblue,
        wasp,
        cardinal,
        iconblue,
        dashpink
    }

    export function setAccent(accent: accents) {
        var prefix = "win-accent-";
        var classes = document.body.className.split(" ")
            .filter(function (c) {
                return c.lastIndexOf(prefix, 0) !== -1;
            }).forEach(function (c) {
                document.body.classList.remove(c);
            });

        document.body.classList.add(prefix + Utilities.accents[accent]);
    }




    export enum panelSlideType {
        LeftIn,
        LeftOut,
        RightIn,
        RightOut
    }


    export enum panelState {
        open,
        closed
    }

    export function panelSlide(element: HTMLElement, type: panelSlideType, init: boolean = false): WinJS.IPromise<any> {

        //element.style.visibility = init ? "hidden" : "visible";
        element.style.display = init ? "none" : "block";

        return WinJS.UI.Animation["slide" + panelSlideType[type]](element)
            .then(() => {
                panelSlideType[type].indexOf("In") != -1
                    ? element.setAttribute("data-panel-state", panelState[panelState.open])
                    : element.setAttribute("data-panel-state", panelState[panelState.closed]);
            })

    }
}













interface StringConstructor {
    format(str: string, ...args: string[]): string;
}

if (!String.format) {
    String.format = function (format) {
        var args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    };
}




interface Array<T> {
    contains(value: T): boolean;
}

Array.prototype.contains = function (value) {
    return this.indexOf(value) > -1;
};



interface Element {
    remove(): void;
}

interface NodeList {
    remove(): void;
}

interface HTMLCollection {
    remove(): void;
}

Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
}
NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
        if (this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
        }
    }
}